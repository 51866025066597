import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalSection from "../../components/vertical-section"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "atelier/atelier-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "atelier/atelier-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "atelier/atelier-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "atelier/atelier-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "atelier/atelier-5.jpg" }) { ...fluidImage }
    photo6: file(relativePath: { eq: "atelier/atelier-6.jpg" }) { ...fluidImage }
  }
`

const AtelierPage = ({ data }) => (
  <Layout title="Ateliér">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalSection>
      <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
      <VerticalText>
        <Heading>Secesní ateliér, Praha 1, 2016</Heading>
        <Text>
          Půdní prostor, nacházející se v bývalé budově c.k. knihoskladu z roku 1900, byl navržen a užíván jako malířský ateliér. Posledních 30 let se však nevyužíval a chátral. Záměrem investora bylo tento prostor rekonstruovat do podoby luxusního bytu. Návrh počítá s výrobou repliky původního secesního ateliérového prosklení s izolačním dvojsklem. Tento prostor jsem rozdělil na obývací a jídelní zónu. Dveřmi z chodby se vchází do obývací části, kterou tvoří dvě sedací soupravy ohraničující vyvýšené pódium, na kterém je umístěný jídelní stůl. Díky vyvýšenému pódiu je umožněn lepší výhled do uliček Prahy 1, a navíc výškově pódium navazuje na ložnici umístěnou v mansardovém krovu. Vytápění místnosti je tvořeno podlahovými konvektory, které jsou zasazeny do vyvýšeného pódia. Obývací prostor netradičně přímo navazuje na velkorysou kuchyni. Té dominuje masivní ostrůvek obložený měděnými deskami s barovým sezením. Ostrůvek, který zároveň schovává televizi, je z jedné strany lemován kuchyňskou linkou, z druhé strany stěnou s kruhovým oknem, oddělující technickou místnost a komoru. Kruhový otvor reaguje na kulaté okno ve fasádě a je zasklen mléčným sklem. Prostor za mléčným sklem je nasvícen a intenzita osvětlení je regulována dle světelné intenzity v místnosti. Návrh se snaží co nejvíce zachovat původní otevřenost prostoru a pouze do něj vkládá nové objekty. Strop navazující na ateliérové prosklení je navržen z akustického perforovaného sádrokartonu a osazen měděnými tyčovinami doplňující noční oblohu. V jídelním koutě se nachází ve štítové stěně kulatá nika, ve které jsou navrženy hodiny.
        </Text>
      </VerticalText>
    </VerticalSection>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <HorizontalImage image={data.photo4.childImageSharp.fluid} />
    <HorizontalImage borderTop image={data.photo5.childImageSharp.fluid} />
    <HorizontalImage borderTop image={data.photo6.childImageSharp.fluid} />
  </Layout>
)

export default AtelierPage
